<template>
  <v-card flat>
    <v-card-title class="primary--text text-bold">Events</v-card-title>
    <v-card-text>
      <v-row v-if="loading">
        <v-col cols="12" sm="3" v-for="item in 9" :key="item">
          <v-skeleton-loader type="card"></v-skeleton-loader>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12" sm="3" v-for="event in events" :key="event._id">
          <v-responsive aspect-ratio="1">
          <v-card class="ma-2" :to="{ name: 'visitor.event-widget', params: { id: $route.params.id, eventId: event._id,  } }">
            <v-img :aspect-ratio="16/9" :src="$getOrignalImageByWidth(event.image, event.imageUserId, event._id, 1200)"></v-img>

            <v-list-item two-line>
              <v-list-item-action class="mr-4 primary--text d-grid text-bold">
                <div class="text-center">{{ event.startsAt | day }}</div>
                <div>{{ event.startsAt | month }}</div>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ event.title }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ event.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          </v-responsive>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { PromoterEventService } from '@services';

export default {
  data() {
    return {
      events: [],
      loading: false,
    };
  },
  created() {
    this.manageUserActivity({event:'venue clicked ==>'+this.$route.params.id});
    this.getGenres();
  },
  methods: {
    async getGenres() {
      this.loading = true;
      const response = await PromoterEventService.getEventsByCode(this.$route.params.id);
      if (response) {
        this.events = response.events;
      }
      this.loading = false;
    },
  },
};
</script>
